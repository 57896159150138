import createTheme from "@mui/material/styles/createTheme";

export const theme = createTheme({
    components:{
      MuiButtonBase: {
        styleOverrides: {
            root: {
                textTransform: 'none'
            },
        },
        defaultProps: {
          color: 'white',
        },
        variants: [
          {
            props: { },
            style: { 

            }
          }
        ]
      },
      MuiTypography: {
        defaultProps: {
          fontSize: 14
        }
      }
    },
    palette: {
        primary: {
          main: "#016ff6",
        },
        secondary: {
          main: "#e8c11b",
        },
    },
})