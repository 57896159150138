export const UPGRADELINK  = {
    'EN' : 'https://calendly.com/flexwhere-katrien-wolfert/flexwhere-free-trial-talk-to-expert',
    'DE' : 'https://calendly.com/flexwhere-sarah-scheller/demo-talk-to-an-expert',
    'NL' : 'https://calendly.com/flexwhere-katrien-wolfert/flexwhere-free-trial-talk-to-expert'
  }
  
  export const PRICINGLINK = {
    'EN' : 'https://flexwhere.co.uk/how-much-costs-flexwhere-price/',
    'DE' : 'https://flexwhere.de/wie-viel-kostet-flexwhere-preis/?_gl=1*pk3y5f*_ga*MTU2OTg3NDE5LjE2MTI0Mjc5Njk.*_ga_WBLKHRLVE5*MTY5MTU1ODE5OC4yMy4xLjE2OTE1NTgyMTMuMC4wLjA.*_gcl_au*MTc5MDQ3NjU0LjE2ODczNTQ4NDU.',
    'NL' : 'https://flexwhere.nl/prijzen/?_gl=1*1n6w51o*_ga*MTU2OTg3NDE5LjE2MTI0Mjc5Njk.*_ga_WBLKHRLVE5*MTY5MTU1ODE5OC4yMy4xLjE2OTE1NTgyMDIuMC4wLjA.'
  }
  