import { LANG } from "../utils";

/**
 * Get qwery params from the URL
 */
 export const getQweryParams = (): any => {
  let queries = (window.location.href.split("?")[1] || "").split("&"),
    result: any = {};
  if (queries.length >= 1 && queries[0] !== "") {
    queries.forEach((query) => {
      const x = query.split("=");
      result[x[0]] = x[1];
    });
  }
  return result;
};
export const getAppLanguage = (path: string) => {
    // const userLanguage = path.replace(/\//g, ''),
    const userLanguage = path.split('/')[1],
    browserLanguage = navigator.language.slice(0, 2),
    languages: string[] = [LANG.EN, LANG.DE, LANG.NL];
    if(languages.indexOf(userLanguage) > -1){
      return userLanguage;
    }
    else if(languages.indexOf(browserLanguage) > -1){
      return browserLanguage;
    }
    else return LANG.EN;
};

/**
 *
 * @param lang
 */
export const loader = async (lang: string | LANG) => {
  try {
    // const response = await import(`../locale/${lang}.json`);
    const response = await fetch(`${process.env.PUBLIC_URL}/locale/${lang}.json`).then(resp => resp.json());
    const translatedStrings = response;
    // Set Document title accordingly
    document.title = "Flexwhere";
    document.documentElement.lang = lang;
    return { flx: translatedStrings };
  }
  catch(exc) {
    console.error(exc)
  }
};
