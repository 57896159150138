import React, { useContext, useEffect } from 'react';
import { FlwContext } from '../context/FlwContext';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { post } from '../utils/network';
import Container from '@mui/material/Container';

import './invite-user.scss';
import { getQweryParams, loader } from '../services';
import { LANG } from '../utils';

const InviteUserComponent = () => {
    const navigate = useNavigate();
    const [params] = React.useState(getQweryParams());
    const { state: flxContext, setState } = useContext(FlwContext);

    const { translation } = flxContext;

    const [formState, setFormState] = React.useState({
        name: "", password: "", confirmPassword: "",
        errors: { name: { notNull:false, validDisplayName: false }, password: { notNull: false, sameConfirmPassword: false, validPasswordRule: false } },
    });
    /**
     * Validate the email link on page load
     * And then call the polling endpoint just once
     * If error navigate to login page with invalid param to enter invalid token flow
     */
    React.useEffect(() => {
        if (params.email === undefined) {
            navigate(`/${flxContext.lang}`)
        }
    })

    const isValidPassword = (password: string): boolean => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$#@$!%*?&()]).{8,256}$/;
        return passwordRegex.test(password);
    }
    const isValidName = (name: string): boolean => {
        const displayNameRegex = /^[\p{L}\p{N},_=.@\s-]+$/u;
        return displayNameRegex.test(name);
    }


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // Validate form inputs
        const { name, password, confirmPassword } = formState;
        const errors = { name: { notNull: false, validDisplayName: false }, password: { notNull: false, sameConfirmPassword: false, validPasswordRule: false } };
        if (name.trim() === '') {
            errors.name.notNull = true;
        } else if (!isValidName(name)) {
            errors.name.validDisplayName = true;
        }

        if (password.trim() === '') {
            errors.password.notNull = true;
        } else if (!isValidPassword(password)) {
            errors.password.validPasswordRule = true;
        }

        if (password.trim() !== confirmPassword.trim()) {
            errors.password.sameConfirmPassword = true;
        }

        // Set validation errors
        setFormState((prevState) => ({
            ...prevState,
            errors,
        }));

        // Submit form if there are no validation errors
        if (errors.name.notNull || errors.name.validDisplayName || errors.password.notNull || errors.password.validPasswordRule || errors.password.sameConfirmPassword) {
            return;
        }
        // send the form data
        params.email = decodeURIComponent(params.email);
        params['name'] = name;
        params['password'] = password;
        try {
            await post(`/api/v1/flw/auth/addUser`, params, {});
            const response = await post(`/api/v1/flw/auth/login`, { email: params.email, password: params.password }, {});
            const data = response.data;
            document.cookie = `sharedJWT=${data.token}; Path=/; Expires=${getExpiryFromJWT(data.token)}`;
            window.location.href = `//${window.location.hostname}`;
        } catch (err) {
            console.log(err)

        }
    };
    const getExpiryFromJWT = (jwt: string): Date => {
        try {
            const user: any = JSON.parse(atob(jwt.split('.')[1]));
            return new Date(user.exp * 1000);
        } catch (e) {
            return new Date(0);
        }
    }

    /**
    * Update the form when user enters values
    * @param e change event
    */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };


    const loadTranslations = async (lang: LANG) => {
        flxContext.lang = lang;
        const tr = await loader(lang);
        setState({ type: 'translation', data: tr.flx })
    }
    /**
  * Check for user's browser language availability in the supported languages
  */
    useEffect(() => {
        loadTranslations(params.language);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.language]);

    return (
        <React.Fragment>
            <Container className='invite-user' component={'section'}>
                <img src={process.env.PUBLIC_URL + '/flexwhere.svg'} alt='FlexWhere' />
                <div className="heading">🙌 {translation?.login?.userform.welcome}</div>
                <div className="description">{translation?.login?.userform.description}:</div>
                <TextField
                    name="name"
                    autoFocus={true}
                    placeholder={translation?.displayname}
                    variant="filled"
                    fullWidth
                    required={true}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    error={Boolean(formState.errors.name.notNull) || Boolean(formState.errors.name.validDisplayName)}
                    inputProps={{
                        maxLength: 255
                    }}
                    helperText={formState.errors.name.validDisplayName ? translation?.login?.userform?.nameRule  :''}
                />
                <TextField
                    type="password"
                    name="password"
                    placeholder={translation?.login?.userform?.password}
                    variant="filled"
                    fullWidth
                    required={true}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    inputProps={{
                        maxLength: 256
                    }}
                    InputProps={{
                        startAdornment: <img src={process.env.PUBLIC_URL + "/images/lock.svg"} alt="lock" />
                    }}
                    helperText={formState.errors.password.validPasswordRule ? translation?.login?.userform?.passwordRule : ''}
                    error={Boolean(formState.errors.password.notNull) || Boolean(formState.errors.password.validPasswordRule)}
                />
                <TextField
                    type='password'
                    name="confirmPassword"
                    placeholder={translation?.login?.userform?.confirmPassword}
                    variant="filled"
                    fullWidth
                    required={true}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    inputProps={{
                        maxLength: 256
                    }}
                    InputProps={{
                        startAdornment: <img src={process.env.PUBLIC_URL + "/images/lock.svg"} alt="lock" />
                    }}
                    helperText={formState.errors.password.sameConfirmPassword ? translation?.login?.userform?.samePassword : ''}
                    error={Boolean(formState.errors.password.notNull) || Boolean(formState.errors.password.sameConfirmPassword)}
                />

                <Button type="submit" variant="contained" className="float-right submit" onClick={handleSubmit}>{translation?.login?.userform?.next}</Button>
            </Container>
        </React.Fragment>
    )
}

export const InviteUser = InviteUserComponent;