import axios, { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import Cookie from "js-cookie";

export const get = (url: string, options?: AxiosRequestConfig) => {
  return axios.get(url, options);
};

export const post = <K = any>(url: string, body: K, options?: AxiosRequestConfig) => {
  return axios.post(url, body, options);
}

export const setCookie = (
  name: string,
  data: string,
  domain: string,
  expDays: number
) => {
  let date = dayjs().add(expDays, "day").toDate();
  Cookie.set(name, data, { path: "/", expires: date, secure: true, domain });
};

export const setAuthToken = (
  name: string,
  data: string,
  domain: string,
) => {
  let date = dayjs().add(1, "hour").toDate();
  Cookie.set(name, data, { path: "/", expires: date, secure: true, domain });
}

export const getCookie = (name: string) => {
  return Cookie.get(name);
}

export const removeCookie = (name: string, domain: string) => {
  Cookie.remove(name, { domain, path: '/', secure: true })
}

export const logError = (origin: string, destination: string, ...other: any[]) => {
  const misc = other.map(o => JSON.stringify(o)).join(' | ');
  fetch('https://hooks.slack.com/services/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', {
    method: 'POST',
    body: JSON.stringify({
      text: `DIFFERENT_BROWSER_LOGIN: start ${origin}, end ${destination}, ${misc}.`
    })
  });
}