import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from "@mui/material/Tooltip";
import { AxiosResponse } from 'axios';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlwContext } from '../../context/FlwContext';
import { IPOrg, IUser, SSOResponse, SSOResponseBody, ValidationResponse } from '../../models/Login';
import { get, getCookie, post, setAuthToken, setCookie } from '../../utils/network';
import { UPGRADELINK,PRICINGLINK } from "../../utils/const/link"
import { LANG } from "../../utils";
import { v4 } from 'uuid';
import { RateLimitError } from '../Login';
// import { getLoginTries, setLoginTries } from '../../utils/loginUtils';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { AccountInfo, PopupRequest } from '@azure/msal-browser';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';

//@ts-ignore-all
/* eslint-disable */ 
const SigninForm = () => {
  const { state: flxContext } = useContext(FlwContext);
  const translation = flxContext.translation;

  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
  const [isResendDisabled, setIsResendDisabled] = React.useState(true);
  const [isRateLimited, setIsRateLimited] = React.useState(false);
  const [messageId, setMessageId] = React.useState<string>("");
  const [isMailSent, setIsMailSent] = React.useState<boolean>(false);
  const [isExpired, setIsExpired] = React.useState<boolean>(false);
  const [orgs, setOrgs] = React.useState<Array<SSOResponseBody>>([]);
  const timeout = React.useRef<any>(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const uuid = React.useRef( getCookie('loginUUID') || v4() );
  // const loginTries = React.useRef<number>( getLoginTries() );

  const request: PopupRequest = {
    scopes: ["User.Read"],
    prompt: "select_account",
    // redirectUri: 'http://localhost:3000/',
  }

  // const redirectRequest: RedirectRequest = {
  //   scopes: ["User.Read"],
  //   prompt: "select_account",
  //   redirectUri: 'http://localhost:3000/', 
  // }

  // useGoogleOneTapLogin({
  //   auto_select: false,
  //   disabled: false,
  //   use_fedcm_for_prompt: false,
  //   onSuccess(credentialResponse) {
  //     console.log(credentialResponse);
  //       // handleSuccess(credentialResponse);
  //   },
  //   onError: () => {
  //       console.log('Login failed');
  //       // setState({ type: 'error', data: 'unknown' });
  //       // navigate('/');
  //   },
  // })

  const { instance, accounts, inProgress } = useMsal();

  const domain = window.location.hostname .toLocaleLowerCase() .split('.') .slice(1, 10) .join('.');
  const isTestEnv = window.location.hostname.includes('.test') || window.location.hostname.includes('localhost');

  /* eslint-disable no-useless-escape */
  const EMAILREGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  const [form, setForm] = React.useState({
    value: { email: "" },
    error: { email: false, code: "" },
  });

  React.useEffect(() => {
    if(!window.location.host.includes('localhost') && flxContext.error === '' && !flxContext.logout) {
      checkIfUserLoggedIn();
    }

    //when user presses back / backspace
    window.addEventListener("popstate", () => {
      clearTimeout(timeout.current);
    });

    if(flxContext.error === 'demo-expired') {
      setIsExpired(true);
    }

    return () => {
      window.removeEventListener("popstate", () => {
        clearTimeout(timeout.current);
      });
      clearTimeout(timeout.current);
    };
  }, []);

  React.useEffect(() => {
    if (location ?.state ?.isSubmitted) {
      setIsMailSent(true);
      setForm((prev) => ({
        ...prev,
        value: { email: location.state.email },
      }));
      setMessageId(location.state.code);
      // window.history.replaceState({}, '', '/signup');
    }
  }, [location])


  const handleEnter = (e: any) => {
    e.preventDefault();
    if (e.key === "Enter" && !isSubmitDisabled) {
      handleSubmit();
    }
  };
  /**
   * Update the form when user enters values
   * @param e change event
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      value: { ...form.value, [e.target.name]: e.target.value },
    });
  };

  const resendEmail = () => handleSubmit();

  // pass email to backend
  const handleSubmit = () => {
    form.error.email = false;
    form.error.code = "";
    setForm({ ...form });
    if (timeout) clearTimeout(timeout.current);

    if (EMAILREGEX.test(form.value.email)) {
      setIsSubmitDisabled(true);
      verifyUser(`/api/v1/flw/auth/${form.value.email}/verifyUser?source=web&deviceId=${uuid.current}`);
    } else {
      form.error.email = true;
      setForm({ ...form });
    }
  };

  /**
   * Send request to /verifyUser
   * errors are shown for non users and T-OTP enabled users
   * @param url the URL to send request to
   */
  const verifyUser = (url: string) => {
    get(url)
      .then((value) => {
        if (value.data.autoAddToken) {
          navigate(`/${flxContext.lang}/signup`, { state: { email: form.value.email, addToken: value.data.autoAddToken } });
        }
        else if (value.status === 200) {
          setIsMailSent(true);
          setMessageId(value.data.uuid);
          setCookie('loginUUID', uuid.current , 'flexwhere.com', 30);

          //Set the number of times the user tries to login
          // setLoginTries(loginTries.current + 1, form.value.email);
          // loginTries.current += 1;

          localStorage.setItem("loginUUID", JSON.stringify({ uuid: value.data.uuid, validFrom: new Date().toISOString() }));

          setIsResendDisabled(true);
          setTimeout(() => {
            setIsResendDisabled(false);
          }, 30000)
        }
      })
      .catch((err) => {
        setIsSubmitDisabled(false);
        if (err.response.data.message === "INVALID_EMAIL") {
          form.error.email = true;
          form.error.code = "INVALID_EMAIL";
          setForm({ ...form });
        } else if (err.response.data.message === "NOT_FOUND") {
          form.error.email = true;
          form.error.code = "NOT_FOUND";
          setForm({ ...form });
        } else if (err.response.data.message === "NOT_SUPPORTED") {
          form.error.code = "NOT_SUPPORTED";
          navigate('/totp')
        } else if(err.response.data.message === "SUSPICIOUS_ACTIVITY") {
          setIsRateLimited(true);
        } else if (err.response.data.message === "DEMO_EXPIRED") {
          setIsExpired(true)
        }
      });
  };
  /**
   * Keep polling until the response becomes 200
   * And then redirect to user's org page by setting a cookie
   */
  const pollForEmailVerification = () => {
    get(`/api/v1/flw/auth/${messageId}/isValidId/?deviceId=${uuid.current}`)
      .then((response: AxiosResponse<ValidationResponse>) => {
        if (response) {
          const data = response.data;

          setAuthToken(
            `flexwhereAccessToken_${data.subDomain}`,
            encodeURIComponent(data.authToken),
            domain
          );
          const ONE_YEAR = 365;
          
          //Set the subdomain cookie for redirection
          setCookie( `flexwhereSubDomain`, data.subDomain, domain, ONE_YEAR );
          // setCookie( 
          //   `accessToken`,
          //   data.authToken,
          //   'flexwhere.com',
          //   28
          // );
          // setCookie( 
          //   `flxUser${isTestEnv ? '_test': ''}`,
          //   JSON.stringify({ ...data, isTest: isTestEnv }),
          //   'flexwhere.com',
          //   28
          // );

          localStorage.setItem('user', JSON.stringify(data));

          // setLoginTries(0)

          //Redirect to the subdomain
          //By replacing web if it exists in the URL
          gotoFlexWhere(data.subDomain);
        }
      })
      .catch(() => {
        clearTimeout(timeout.current);
        poll();
      });
  };

  /**
   * Navigates to FlexWhere org
   * @param subDomain the subdomain to navigate to
   */
  const gotoFlexWhere = (subDomain: string) => {
    const proto = window.location.protocol;
    const host = window.location.hostname.replace("web", subDomain);
    window.location.href = `${proto}//${host}`;
  }

  /**
   * This will work if user goes to subdomain.flexwhere.com 
   */
  const checkIfUserLoggedIn = async () => {
    // When user is in subDomain.flexwhere.com
    const longLivedToken = localStorage.getItem("token");
    if (longLivedToken?.length) {
      window.location.href = `${window.location.protocol}//${window.location.host}`;
      return;
    }

    // Get the subdomain
    const cookieToken = getCookie('flexwhereSubDomain');
    if (cookieToken?.length > 0 && !window.location.hostname.includes('localhost') ) {
      console.log('cookie found. redirecting...');
      gotoFlexWhere(cookieToken);
      return;
    }

    // Get the last auth data, if present
    const userData: ValidationResponse = JSON.parse( localStorage.getItem('user') ??  '{}');
    if(userData.authToken) {
      console.log('user data found. Redirecting with data');
      // Verify the token for validity
      get(`https://${userData.subDomain}.flexwhere.com/api/v1/flw/users/me`, {
        headers: {
          'authorization': userData.authToken
        }
      }).then((user: AxiosResponse<IUser>) => {
        gotoFlexWhere(user.data.subDomain);
      })
      .catch(ex => {
        //Remove user data when token is invalid
        localStorage.removeItem('user');

        // Check whether user is in a valid IP address
        console.log('Cookie not found. Finding org via IP');
        get('/api/v1/flw/orgs/me')
        .then((ipOrg: AxiosResponse<IPOrg>) => {
          if(flxContext.error === '') {
            window.location.href = ipOrg.data[0]?.trayConfigUrl.replace('/tray/config', '');
          }
        })
        .catch(console.error);
      })
    }

    // When cookie and token are not found
    // Check whether user is in a valid IP address
    if(!cookieToken && !userData.authToken) {
      try {
        // If the device ID exists
        if(getCookie('loginUUID')) {
          acquireTokenWithDeviceId()
          .then((response: AxiosResponse<any>) => {
            if(response){
              const data = response.data;
              // Set accessToken
              setAuthToken(
                `flexwhereAccessToken_${data.subDomain}`,
                encodeURIComponent(data.accessToken),
                domain,
              );
              // Store now() as last login
              setCookie('lastLogin', dayjs().toISOString(), 'flexwhere.com', 90);
      
              // Store user data for next time
              // localStorage.setItem('user', JSON.stringify(data));
      
              setCookie(`flexwhereSubDomain`, data.subDomain, domain, 28 );

              gotoFlexWhere(data.subDomain);
            }
          })
          .catch(async (error) => {
            console.error(error);
            console.log('Device not found. Finding org via IP');
            const ipOrg: AxiosResponse<IPOrg> = await get('/api/v1/flw/orgs/me');
            window.location.href = ipOrg.data[0]?.trayConfigUrl?.replace('/tray/config', '');
          })
          return;
        }
        console.log('Cookie not found. Finding org via IP');
        const ipOrg: AxiosResponse<IPOrg> = await get('/api/v1/flw/orgs/me');
        window.location.href = ipOrg.data[0]?.trayConfigUrl?.replace('/tray/config', '');
      }
      catch(ex) {
        console.error(ex);
      }
    }
  };

  /**
   * Start polling when messageId is received
   */
  React.useEffect(() => {
    clearTimeout(timeout.current);
    poll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId]);

  const poll = () => {
    if (messageId) {
      timeout.current = setTimeout(pollForEmailVerification, 5000);
    }
  };


const TaltoSales = () => {
    const link = flxContext.lang == LANG.DE ? UPGRADELINK.DE : UPGRADELINK.EN;
    window.open(link, '_blank');
}

const Viewpricing = () =>{
    const link =
    flxContext.lang == LANG.DE
      ? PRICINGLINK.DE
      : flxContext.lang == LANG.NL
      ? PRICINGLINK.NL
      : PRICINGLINK.EN;
    window.open(link, '_blank');
}

  const getEmailError = () => {
    if (form.error.code === "INVALID_EMAIL") return "emailNotValid";
    else if (form.error.code === "NOT_FOUND") return "m_email_notfound";
    return "";
  };

  /**
   * Acquire the token silently
   * If it fails fallback to popup
   * @param account 
   */
  const msLoginSilent = (account?: AccountInfo) => {
    if(account) {
      instance.setActiveAccount(account);
    }
   
    instance.acquireTokenSilent(request)
    .then((response) => {
      acquireTokenFromFlexwhere('microsoft', response.accessToken);
    })
    .catch((error) => {
      console.error(error);
      msLoginPopup();
    });
  }

  /**
   * Acquire the token with a popup
   * @param account 
   */
  const msLoginPopup = (account?: AccountInfo) => {
    if(account) {
      instance.setActiveAccount(account);
    }

    instance.clearCache();
    
    const allowedOrigins = [ 'web.flexwhere.com', 'web.test.flexwhere.com', 'localhost' ];
    // If the current url is web
    // Because the redirection won't complete for cross domains
    if(allowedOrigins.includes(window.location.hostname.toLowerCase())) {
      navigate('/start-microsoft');
    } else {
      const DOMAIN = window.location.hostname .toLocaleLowerCase() .split('.') .slice(1, 10) .join('.') ||  'test.flexwhere.com';
      const url = `https://web.${DOMAIN}/login/start-microsoft`;
      window.location.assign(url);
    }
  }

  /**
   * Acquire the token with a popup
   * @param account 
   */
  const googleLoginRedirect = () => {
    const allowedOrigins = [ 'web.flexwhere.com', 'web.test.flexwhere.com', 'localhost' ];
    // If the current url is web
    // Because the redirection won't complete for cross domains
    if(allowedOrigins.includes(window.location.hostname.toLowerCase())) {
      navigate('/start-google');
    } else {
      const DOMAIN = window.location.hostname .toLocaleLowerCase() .split('.') .slice(1, 10) .join('.') ||  'test.flexwhere.com';
      const url = `https://web.${DOMAIN}/login/start-google`;
      window.location.assign(url);
    }
  }

  const acquireTokenFromFlexwhere = (source: 'microsoft' | 'google' | 'apple', token: string) => {
    const body = {
      platform: 'web',
      source: source,
      deviceId: uuid.current,
      token: token,
      bundle: ''
    };
    post('/api/v1/flw/auth/ssoLogin', body)
    .then((response: AxiosResponse<SSOResponse>) => {
      console.log(response);
      const {data} = response;
      if(data.body.length === 1) {
        openFlexwhere(data.body[0]);
      } else {
        // setOrgs(data.body);
        console.log(data.body);
        navigate(`/orgs`, { state: { orgs: data.body } });
      }
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const openFlexwhere = (data: SSOResponseBody) => {
    setAuthToken(
      `flexwhereAccessToken_${data.subDomain}`,
      encodeURIComponent(data.token),
      domain
    );
    const ONE_YEAR = 365;
    
    //Set the subdomain cookie for redirection
    setCookie( `flexwhereSubDomain`, data.subDomain, domain, ONE_YEAR );

    localStorage.setItem('user', JSON.stringify(data));

    //Redirect to the subdomain
    //By replacing web if it exists in the URL
    gotoFlexWhere(data.subDomain);
  }
  
  const getMsUser = () => {
    const accounts = instance.getAllAccounts();
    if(accounts.length > 0) {
      // const first = accounts[0];
      return accounts.map(account => (
        <Button onClick={() => msLoginSilent(account)} variant='outlined' className='microsoft-login' key={account.username}>
          <img src={process.env.PUBLIC_URL + "/images/microsoft.svg"} alt="" style={{ width: 20 }} />
          &nbsp;
          &nbsp;
          { 'Login as ' }
          {
            account?.name || account?.username
          }
        </Button>
      ))
    } else {
      return (
        <Button onClick={() => msLoginSilent()}>
          {translation?.login?.microsoft}
        </Button>
      )
  
    }
  }

  const acquireTokenWithDeviceId = () => {
    return get('/api/v1/flw/auth/isValidDeviceId', {
      headers: {
        deviceId: uuid.current
      }
    })
  }

  return (
      <Box className="sign-up-form" minWidth={"25rem"} maxWidth={"50%"} component={"section"}>

      {/* Free trial expired */}
      {
        isExpired && (
            <Box className="verify-email text-center">
              <img
                src={process.env.PUBLIC_URL + "/flexwhere.svg"}
                alt="FlexWhere"
                className="flw-logo"
              />
              <h5 className="freetrialtitle">
                {translation ?.freetrialexpired ?.freetrialexpiredtitle }

              </h5>
              <p style={{ fontSize: 13, marginTop: -9 }}>
                {translation ?.freetrialexpired ?.freetrialexpiredsubtitle }

              </p>
            
              <Button variant="contained" className="talktosales" onClick={TaltoSales}>{ translation?.freetrialexpired?.talktosales }</Button><br /><br />
              <Button variant="outlined" className="viewpricebtn" onClick={Viewpricing}>{ translation?.freetrialexpired?.viewpricingplan} </Button>
              <div className="freetrialerror" dangerouslySetInnerHTML={{__html: translation?.freetrialexpired?.freetrialerror}}></div>
            </Box>
        )
      }
      {/* Signin form */}
      {
        !isMailSent && !isExpired && !isRateLimited &&
        <Box>
          <img
            src={process.env.PUBLIC_URL + "/flexwhere.svg"}
            alt="FlexWhere"
            className="flw-logo"
          />
          {flxContext.logout &&
            <Typography component="p" sx={{ color: '#f43636', fontSize: 14 }} mt={5} >
              {translation ?.login ?.loggedOut}
            </Typography>
          }

          <Typography component="p" className="text" mt={4} mb={3}>
            {flxContext.invalid
              ? translation ?.login ?.loginExpiredRetry
                : translation ?.login ?.loginEnterEmail}
          </Typography>
          <TextField
            id="email"
            name="email"
            error={form.error.email}
            onChange={handleChange}
            onKeyUp={handleEnter}
            autoFocus={true}
            helperText={form.error.email && translation[getEmailError()]}
            label={translation.emailAddress}
            variant="standard"
            fullWidth
          />
          <Button className="submit-btn" disabled={isSubmitDisabled} onClick={handleSubmit} color="primary" variant="contained" fullWidth>
            {translation.m_lbl_login}
          </Button>
          {/* { loginTries.current > 5 &&
            <p className="attempts-counter">{ 10 - loginTries.current} attempts left </p>
          } */}

          <Divider orientation='horizontal' color="#000"></Divider>
          <p className='or'>or </p>

          {/* <AuthenticatedTemplate>
            { getMsUser() }

            <Button onClick={() => msLoginPopup()} variant='outlined' className='microsoft-login'>
              <img src={process.env.PUBLIC_URL + "/images/microsoft.svg"} alt="" />
              &nbsp;
              { translation.login?.withMicrosoft }
            </Button>
          </AuthenticatedTemplate> */}

          {/* <Button onClick={() => googleLoginRedirect()} variant='outlined' className='microsoft-login'>
            <img src={process.env.PUBLIC_URL + "/images/google.svg"} alt="" />
            &nbsp;
            { translation.login?.withGoogle }
          </Button> */}

          <UnauthenticatedTemplate>
            <Button onClick={() => msLoginPopup()} variant='outlined' className='microsoft-login'>
              <img src={process.env.PUBLIC_URL + "/images/microsoft.svg"} alt="" />
              &nbsp;
              { translation.login?.withMicrosoft }
            </Button>
          </UnauthenticatedTemplate>

          { flxContext.error === 'sso-user-not-found' &&
          <>
            <p className="error-user">{ translation.login?.error?.userNotFound }</p>
            <ul>
              <li className="error-user">{ translation.login?.error?.useCompanyEmail }</li>
              <li className="error-user">{ translation.login?.error?.contactIT }</li>
            </ul>
          </>
          }
          { flxContext.error === 'unknown' &&
          <>
            <p className="error-user">{ translation.login?.somethingWrong }</p>
          </>
          }

          {/* <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
              <span>This will only render for authenticated users.</span>
          </MsalAuthenticationTemplate> */}

          <p className="privacy-policy" dangerouslySetInnerHTML={ { __html: translation.login?.agreeWithPrivacy} }></p>

          {/* <GoogleLogin
            onSuccess={credentialResponse => {
              console.log(credentialResponse);
            }}
            // use_fedcm_for_prompt={false}
            // state_cookie_domain='flexwhere.com'
            // useOneTap
            type='standard'
            ux_mode='popup'
            width={250}
            login_uri='http://localhost:3000/login/google'
            onError={() => {
              console.log('Login Failed');
            }}
          /> */}

        </Box>
      }

      {/* Mail sent message */}
      {
        isMailSent && !isExpired && !isRateLimited &&
        <Box
          component={"section"}
          style={{ display: isMailSent ? "block" : "none" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/MAIL.svg"}
            alt="FlexWhere"
          />
          <Typography
            component="p"
            className="sent-mail"
            style={{ fontSize: 14 }}
            mt={4}
            mb={4}
          >
            {translation ?.login ?.loginEmailSent}
          </Typography>
          <Typography component="p" style={{ fontSize: 12 }} mt={4} mb={6}>
            {translation ?.login ?.loginNotReceived}{" "}
            <Tooltip title={ isResendDisabled ? translation?.login?.wait30 : '' }>
              <span>
                <Button
                  size="small"
                  className="send-again"
                  disabled={isResendDisabled}
                  onClick={resendEmail}
                  variant="text"
                >
                  {translation ?.login ?.loginSendEmailAgain}
                </Button>
              </span>
            </Tooltip>
          </Typography>

          {location ?.state ?.isSubmitted &&
            <Button sx={{ display: 'flex',border: '1px solid #016ff6' }} variant='outlined' onClick={() => navigate(-1)}>
              {translation ?.login ?.backToUser}
            </Button>
          }
        </Box>
      }
      {/* Rate limit message */}
      { isRateLimited && <RateLimitError/> }
      </Box>
  );
}

export default SigninForm;
