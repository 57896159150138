import React, { useContext } from "react";
import "./Login.scss";
import { FlwContext } from "../context/FlwContext";
import { Typography, Box, ThemeProvider } from "@mui/material";
import { theme } from "../utils/theme";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import SigninForm from "./forms/SigninForm";
import BookingsComponent from "../Bookings/bookings";
import { getQweryParams, loader } from "../services";

const LoginComponent = () => {
  const { state: flxContext, setState } = useContext(FlwContext);
  const { lang } = useParams();

  React.useEffect(() => {
    flxContext.lang = lang;
    loader(lang).then(tr => {
      setState({ type: 'translation', data: tr.flx })
    })
    .catch(e =>console.error)
  }, [lang])

  const bookingAction = React.useMemo(() => {
    return getQweryParams().userAction;
  }, [])


  return (
    <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<SigninForm />} />
          <Route path="/bookings-extend" element={<BookingsComponent action={bookingAction} />} />
          <Route path="*" element={<Navigate to={`/${flxContext.lang}/`} />} />
        </Routes>
    </ThemeProvider>
  );
};

export const TotpError = () => {
  const {state: flxContext} = React.useContext(FlwContext);
  const {translation} = flxContext;
  const navigate = useNavigate();

  React.useEffect(() => {
    //when user presses back / backspace
    window.addEventListener("popstate", () => {
      navigate(`/${flxContext.lang}/`);
    });

    return () => {
      window.removeEventListener("popstate", () => {
        navigate(`/${flxContext.lang}/`);
      });
    };
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <Box className="totp-error" component={"section"}>
        <Box
          component={"section"}
        >
          <img
            src={process.env.PUBLIC_URL + "/flexwhere.svg"}
            alt="FlexWhere"
            className="flw-logo"
          />
          <Typography
            component="h2"
            marginBottom={3}
            marginTop={2}
          > 
            <img 
              src={process.env.PUBLIC_URL + "/images/error.svg"} 
              alt="error"
              style={{ height: 20 }}
            />{" "}
            {translation?.loginTOTPMessage}
          </Typography>

          <Typography component={"p"} marginBottom={2}>
            { translation.login?.loginTOTPDownload }
          </Typography>
          
          <Box className="download-qr">
            <Box>
              <p>{translation.login?.flwForiOS}:</p>
              <a href="https://apps.apple.com/us/app/flexwhere/id1164455585/" target="_blank">
                <img src={process.env.PUBLIC_URL + "/images/FW-QR-apple.jpg"} alt="App Store FlexWhere" />
              </a>
            </Box>
            <Box>
              <p>{translation.login?.flwForAndroid}:</p>
              <a href="https://play.google.com/store/apps/details?id=com.FlexWhere.nl" target="_blank">
                <img src={process.env.PUBLIC_URL + "/images/FW-QR-playstore.png"} alt="App Store FlexWhere" />
              </a>
            </Box>
          </Box>
          
          <p className="link">
            {translation?.login?.loginCheckSupport}{" "}
            <a
              href="https://support.flexwhere.com"
              target="_blank"
              rel="noreferrer"
              >
              support.flexwhere.com
            </a>
          </p>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export const RateLimitError = () => {
  const {state: flxContext} = React.useContext(FlwContext);
  const {translation} = flxContext;
  return (
    <ThemeProvider theme={theme}>
      <Box className="" component={"section"}>
        <Box
          component={"section"}
        >
          <img
            src={process.env.PUBLIC_URL + "/flexwhere.svg"}
            alt="FlexWhere"
            className="flw-logo"
          />
          <Typography
            component="h2"
            marginBottom={3}
            marginTop={2}
            fontFamily={"Roboto-Medium"}
          > 
            {translation?.login?.tryAgain}
          </Typography>

          <Typography component={"p"} marginBottom={0}>
          { translation.login?.rateLimitMessage }
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export const Login = LoginComponent;
