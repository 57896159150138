import React, { useContext, useEffect } from 'react';
import { FlwContext } from '../context/FlwContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import { getCookie, post, setAuthToken, setCookie } from '../utils/network';
import Container from '@mui/material/Container';
import { getQweryParams, loader } from '../services';
import { SSOResponse, SSOResponseBody } from '../models/Login';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import './Organisation.scss';
import { AxiosResponse } from 'axios';
import { v4 } from 'uuid';
import { LANG } from '../utils';
import { AuthenticationResult, AuthorizationCodeRequest, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { useGoogleLogin } from '@react-oauth/google';

const OrganisationSelect = () => {
    const DOMAIN = window.location.hostname.toLocaleLowerCase().split('.').slice(1, 10).join('.') || 'test.flexwhere.com';

    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { hash, pathname, state } = useLocation();
    const { state: flxContext, setState } = useContext(FlwContext);

    const gLogin = useGoogleLogin({ 
        flow: 'implicit',
        prompt: 'select_account',
        scope: 'profile email',
        onSuccess: (tokenResponse) => {
            console.log(tokenResponse);
        },
    })

    const uuid = React.useRef(getCookie('loginUUID') || v4());

    const { translation } = flxContext;

    const [orgs, setOrgs] = React.useState<SSOResponseBody[]>([]);

    React.useEffect(() => {
        if (state && state.orgs) {
            console.log(state.orgs);
            const organisations: SSOResponseBody[] = state.orgs;
            setOrgs(organisations);
        }
    }, [])

    // useEffect(() => {
    //     // instance.acquireTokenSilent({
    //     //     scopes: ['user.read'],
    //     //     account: instance.getAllAccounts()[0]
    //     // })
    //     // .then(response => {
    //     //     console.log(response);
    //     //     acquireTokenFromFlexwhere('microsoft', response.accessToken);
    //     // }).catch(error => {
    //     //     console.error(error);
    //     // })
    //     console.log(hash, isAuthenticated, inProgress)

    //     instance.initialize().then(() => {
    //         console.log(inProgress)
    //         if(hash && inProgress === InteractionStatus.None) {
    //             console.log('Acquiring the token');
    //             instance.handleRedirectPromise(hash)
    //             .then(response => {
    //                 console.log(response)
    //                 if(response) {
    //                     acquireTokenFromFlexwhere('microsoft', response.accessToken);
    //                 } 
    //             })
    //             .catch(error => {
    //                 console.error(error);
    //             })
    //         }
    //     });
    // }, [instance, hash, isAuthenticated, inProgress])

    useEffect(() => {
        const callbackId = instance.addEventCallback((event: EventMessage) => {
            console.log(event)
            if ((event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) && event.payload) {
              // Set the active account - this simplifies token acquisition
              const authResult = event.payload as AuthenticationResult;
              instance.setActiveAccount(authResult.account);
              acquireTokenFromFlexwhere('microsoft', authResult.accessToken);
            }
        });

        return () => {
            instance.removeEventCallback(callbackId);
        }
    }, [])

    // React.useEffect(() => {
    //     //when user presses back / backspace
    //     window.addEventListener("popstate", () => {
    //       navigate(`/${flxContext.lang}/`);
    //     });
    
    //     return () => {
    //       window.removeEventListener("popstate", () => {
    //         navigate(`/${flxContext.lang}/`);
    //       });
    //     };
    // }, []);


    const acquireTokenFromFlexwhere = (source: 'microsoft' | 'google' | 'apple', token: string) => {
        const body = {
            platform: 'web',
            source: source,
            deviceId: uuid.current,
            token: token,
            bundle: ''
        };
        post('/api/v1/flw/auth/ssoLogin', body)
            .then((response: AxiosResponse<SSOResponse>) => {
                const { data } = response;
                if (data.body.length === 1) {
                    openFlexwhere(data.body[0]);
                } else {
                    setOrgs(data.body);
                }
            })
            .catch((error) => {
                if(error?.response?.data?.message === 'ORG_NOT_FOUND') {
                    // flxContext.error = 'sso-user-not-found';
                    setState({ type: 'error', data: 'sso-user-not-found' })
                    setTimeout(() => {
                        navigate(`/${flxContext.lang}/`);
                    }, 100)
                } else if(error?.response?.data?.message === 'DEMO_EXPIRED') {
                    setState({ type: 'error', data: 'demo-expired' });
                    setTimeout(() => {
                        navigate(`/${flxContext.lang}/`);
                    }, 100)
                } else if(error?.response?.data?.message === 'TOTP_ENABLED_SO_NOT_SUPPORTED') {
                    setState({ type: 'error', data: 'totp' });
                    setTimeout(() => {
                        navigate(`/totp`);
                    }, 100)
                }
            })
    }

    const loadTranslations = async (lang: LANG) => {
        flxContext.lang = lang;
        const tr = await loader(lang);
        setState({ type: 'translation', data: tr.flx })
    }
    /**
  * Check for user's browser language availability in the supported languages
  */
    useEffect(() => {
        loadTranslations(flxContext.lang as LANG);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openFlexwhere = (data: SSOResponseBody) => {
        setAuthToken(
            `flexwhereAccessToken_${data.subDomain}`,
            encodeURIComponent(data.token),
            DOMAIN
        );
        const ONE_YEAR = 365;

        //Set the subdomain cookie for redirection
        setCookie(`flexwhereSubDomain`, data.subDomain, DOMAIN, ONE_YEAR);

        localStorage.setItem('user', JSON.stringify(data));

        //Redirect to the subdomain
        //By replacing web if it exists in the URL
        gotoFlexWhere(data.subDomain);
    }

    /**
     * Navigates to FlexWhere org
     * @param subDomain the subdomain to navigate to
     */
    const gotoFlexWhere = (subDomain: string) => {
        const proto = window.location.protocol;
        instance.clearCache();
        const host = window.location.hostname.replace("web", subDomain);
        window.location.href = `${proto}//${host}`;
    }

    const goBack = () => {
        instance.clearCache();
        setState({ type: 'error', data: orgs.length > 1 ? 'user-close': 'unknown' });
        navigate('/', { state: null });
    }

    return (
        <React.Fragment>
            <Container className='organisation-select' component={'section'}>
                <img src={process.env.PUBLIC_URL + '/flexwhere.svg'} alt='FlexWhere' />

                {
                    orgs.length === 0 &&
                    <Box margin="1rem auto" maxHeight={"5rem"} justifyContent={"center"} display={"flex"}>
                        <CircularProgress />
                    </Box>
                }
                {/* Only show the text if the API succeeds */}
                {
                    orgs.length > 1 &&
                    <>
                        <p>{ translation.login.selectOrganisation }</p>

                        <Box className="organisations">
                            {orgs.map(body => <Button onClick={() => openFlexwhere(body)} variant='outlined' size='large' key={body.subDomain}> {body.subDomain} </Button>)}
                        </Box>
                    </>
                }
                <Button variant='text' onClick={() => goBack()} color='info' className='back-btn'> { translation.back } </Button>
            </Container>
        </React.Fragment>
    )
}

export const Organisation = OrganisationSelect;