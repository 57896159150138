import React, { useContext, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { EventMessage, InteractionType, PopupRequest } from "@azure/msal-browser";
import { useGoogleLogin, GoogleLogin, CredentialResponse, useGoogleOneTapLogin } from "@react-oauth/google";
import { acquireTokenFromFlexwhere, DOMAIN, openFlexwhere } from "../login-utils";
import { getCookie, post } from "../../utils/network";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { FlwContext } from "../../context/FlwContext";
import { AxiosResponse } from "axios";
import { SSOResponse } from "../../models/Login";

interface ISsoComponentProps {
    provider: 'microsoft' | 'google' | 'apple';
}
export function SsoComponent({ provider }: ISsoComponentProps) {
    const uuid = React.useRef( getCookie('loginUUID') || v4() );
    const navigate = useNavigate();
    const { state, setState } = useContext(FlwContext);

    const request: PopupRequest = {
        scopes: ["User.Read"],
        prompt: "select_account",
    }
    const msal = useMsal();

    // const login = useGoogleLogin({
    //     onSuccess: (tokenResponse) => {
    //       console.log("ID Token:", tokenResponse.credential);
    //     },
    //     onError: () => console.log("Login Failed"),
    //     auto_select: true, // Enables automatic sign-in if the user is already logged in
    // });
    
    // useGoogleOneTapLogin({
    //     auto_select: false,
    //     disabled: provider === 'microsoft',
    //     use_fedcm_for_prompt: true,
    //     onSuccess(credentialResponse) {
    //         handleSuccess(credentialResponse);
    //     },
    //     // onNonOAuthError: (nonOAuthError) => {
    //     //     console.log(nonOAuthError);
    //     //     setState({ type: 'error', data: 'unknown' });
    //     //     navigate(`/${state.lang}`);
    //     // },
    //     onError: () => {
    //         console.log('Login failed');
    //         setState({ type: 'error', data: 'unknown' });
    //         navigate('/');
    //     },

    // })

    const gLogin = useGoogleLogin({ 
        flow: 'implicit',
        prompt: 'select_account',
        // ux_mode: 'popup',
        // redirect_uri: `${window.location.origin}/${state.lang}/google`,
        // redirect_uri: `http://localhost:3000/login/google`,
        scope: 'profile email openid',
        onSuccess: (tokenResponse) => {
            console.log(tokenResponse);
            acquireTokenFromFlexwhere('google', tokenResponse.access_token, uuid.current)
            .then((response: AxiosResponse<SSOResponse>) => {
                const { data } = response;
                if (data.body.length === 1) {
                    openFlexwhere(data.body[0]);
                } else {
                    console.log(data.body);
                    navigate(`/google`, { state: { orgs: data.body } });
                }
            })
            .catch((error) => {
                // console.log(error?.response, state);
                if(error?.response?.data?.message === 'ORG_NOT_FOUND') {
                    setState({ type: 'error', data: 'sso-user-not-found' })
                    setTimeout(() => {
                        navigate(`/${state.lang}/`);
                    }, 100)
                } else if(error?.response?.data?.message === 'DEMO_EXPIRED') {
                    setState({ type: 'error', data: 'demo-expired' });
                    setTimeout(() => {
                        navigate(`/${state.lang}/`);
                    }, 100)
                } else if(error?.response?.data?.message === 'TOTP_ENABLED_SO_NOT_SUPPORTED') {
                    setState({ type: 'error', data: 'totp' })
                    setTimeout(() => {
                        navigate(`/totp`);
                    }, 100)
                }
                console.error(error);
            })
        },
        onNonOAuthError: (nonOAuthError) => {
            console.log(nonOAuthError);
            setState({ type: 'error', data: 'unknown' });
            navigate(`/${state.lang}`);
        },
        onError: (error) => {
            console.log(error);
            setState({ type: 'error', data: 'unknown' });
            navigate('/');
        }
    })

    const handleSuccess = (credentialResponse: CredentialResponse) => {
        console.log(credentialResponse);
        acquireTokenFromFlexwhere('google', credentialResponse.credential, uuid.current)
        .then((response: AxiosResponse<SSOResponse>) => {
            console.log(response);
            const { data } = response;
            if (data.body.length === 1) {
                openFlexwhere(data.body[0]);
            } else {
                console.log(data.body);
                navigate(`/google`, { state: { orgs: data.body } });
            }
        })
        .catch((error) => {
            console.log(error.response);
            if(error?.response?.data?.message === 'ORG_NOT_FOUND') {
                setState({ type: 'error', data: 'sso-user-not-found' })
                setTimeout(() => {
                    navigate(`/${state.lang}/`);
                }, 100)
            } else if(error?.response?.data?.message === 'DEMO_EXPIRED') {
                setState({ type: 'error', data: 'demo-expired' });
                setTimeout(() => {
                    navigate(`/${state.lang}/`);
                }, 100)
            } else if(error?.response?.data?.message === 'TOTP_ENABLED_SO_NOT_SUPPORTED') {
                setState({ type: 'error', data: 'totp' })
                setTimeout(() => {
                    navigate(`/${state.lang}/totp`);
                }, 100)
            }
            console.error(error);
        })
    }

    useEffect(() => {
        if (msal.inProgress === InteractionType.None && provider === "microsoft") {
            msal.instance.initialize().then(() => {
                msal.instance.acquireTokenRedirect(request)
                .then()
                .catch(ex => {
                    console.log(ex)
                    // msal.instance.clearCache();
                });
            })
        }
    }, [msal.inProgress])

    React.useEffect(() => {
        const callbackId = msal.instance.addEventCallback((event: EventMessage) => {
            console.log(event);
        });
    
        return () => {
            msal.instance.removeEventCallback(callbackId);
        }
    }, [])

    React.useEffect(() => {
        if(provider === 'google') {
            setTimeout(() => {
                console.log('Starting Google')
                gLogin();
            }, 500)
        }
    }, [gLogin])

    // useEffect(() => {
    //     msal.instance.initialize();
    //     const callbackId = msal.instance.addEventCallback((event: EventMessage) => {
    //         console.log(event)
    //         if(event.eventType === EventType.RESTORE_FROM_BFCACHE) {
    //             msal.instance.clearCache();
    //         } else if(event.eventType === EventType.HANDLE_REDIRECT_END) {
    //             msal.instance.clearCache();
    //         }
    //         else {
    //             console.log(msal.inProgress)
    //             // if (msal.inProgress === InteractionType.None && provider === "microsoft") {
    //             if (provider === "microsoft") {
    //                 msal.instance.acquireTokenRedirect(request)
    //                 .then()
    //                 .catch(ex => {
    //                     console.log(ex)
    //                     // msal.instance.clearCache();
    //                 });
    //             }
    //         }
    //     });

    //     return () => {
    //         msal.instance.removeEventCallback(callbackId);
    //     }
    // }, [])


    return (
        <>
            {/* {
                provider === 'google' &&
                // <GoogleLogin
                //     onSuccess={credentialResponse => {
                //         console.log(credentialResponse);
                //     }}
                //     auto_select

                //     type='standard'
                //     ux_mode='redirect'
                //     width={250}
                //     login_uri='https://web.test.flexwhere.com'
                //     onError={() => {
                //         console.log('Login Failed');
                //     }}
                // />
                // <div className="invisible" id="google-btn">
                //     <GoogleLogin
                //         onSuccess={credentialResponse => {
                //             // console.log(credentialResponse);
                //             handleSuccess(credentialResponse);
                //         }}
                //         type='standard'
                //         ux_mode='redirect'
                //         width={250}
                //         // login_uri='http://localhost:3000/login/google'
                //         login_uri={`https://web.${DOMAIN}/login/google`}
                //         onError={() => {
                //             console.log('Login Failed');
                //         }}
                //     />
                // </div>
            } */}
            {/* <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} /> */}
        </>
    );
}
