import React, { createContext } from "react";
import { LANG } from "../utils";

export interface FlxContextInterface{
  lang: string,
  invalid: boolean,
  logout?: boolean,
  translation: any,
  error: string;
}

const FlwContext = createContext<{state: FlxContextInterface, setState: any}>({state:{lang: LANG.NL, invalid: false, translation: {}, error: ''}, setState: () => null});

const FlwContextProvider = (props: any) => {

  const contextValue = {
    lang: LANG.EN,
    invalid: false,
    logout: false,
    error: '',
    translation: {}
  };

  const reducer = (prev: any, action: any) => {
    return {...prev, [action.type]: action.data};
  }

  const [state, setState] = React.useReducer(reducer, contextValue);

  return (
    <FlwContext.Provider value={{state, setState}}>
      {props.children}
    </FlwContext.Provider>
  );
};
const FlwContextConsumer = FlwContext.Consumer;

export { FlwContext, FlwContextProvider, FlwContextConsumer };
