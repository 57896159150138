import React from "react";
import "./App.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { FlwContext, FlwContextProvider } from "./context/FlwContext";
import { Layout } from "./Layout/Layout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { GOOGLE_CLIENT_ID, MS_CLIENT_ID } from "./utils/constants";
import { PublicClientApplication } from "@azure/msal-browser";
import { ErrorBoundary } from "react-error-boundary";

function AppComponent() {
  const languages = ["en", "de", "nl"];
  const {state: flxContext} = React.useContext(FlwContext);
  const DOMAIN = window.location.hostname .toLocaleLowerCase() .split('.') .slice(1, 10) .join('.') ||  'test.flexwhere.com';

  const pca = React.useRef(
    new PublicClientApplication({
      auth: {
        clientId: MS_CLIENT_ID,
        redirectUri: `https://web.${DOMAIN}/login/microsoft`,
        // redirectUri: `http://localhost:3000/login/microsoft`,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
    })
  )

  return (
    <ErrorBoundary fallback={<></>}>
      <FlwContextProvider>
        <MsalProvider instance={pca.current}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Routes>
                <Route path="/*" element={<Layout />} />
                <Route
                  path="/*"
                  element={
                    <Navigate
                      to={`/${
                        languages.indexOf("en") !== -1
                          ? navigator.language.slice(0, 2)
                          : flxContext.lang
                      }`}
                    />
                  }
                /> 
              </Routes>
            </BrowserRouter>
          </GoogleOAuthProvider>
        </MsalProvider>
      </FlwContextProvider>
    </ErrorBoundary>
  );
}

export default AppComponent;
