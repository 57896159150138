import React, { useEffect } from "react";
import { Login, TotpError } from "../Login/Login";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { FlwContext } from "../context/FlwContext";
import { getAppLanguage, loader } from "../services";
import UserForm from "../Login/forms/UserForm";
import { InviteUser } from "../InviteUser/InviteUser";
import { Verify } from "../Verify/Verify";
import { Organisation } from "../Organisation/Organisation";
import { SsoComponent } from "../Login/sso/Sso";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MS_CLIENT_ID } from "../utils/constants";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { AxiosResponse } from "axios";
import { DOMAIN } from "../Login/login-utils";
import { SSOResponse, SSOResponseBody } from "../models/Login";
import { getCookie, post, setAuthToken, setCookie } from "../utils/network";
import { v4 } from "uuid";

/* eslint-disable react-hooks/exhaustive-deps */
function LayoutComponent() {
  
  const { state: flxContext, setState } = React.useContext(FlwContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [lang] = React.useState(getAppLanguage(location.pathname));
  const uuid = React.useRef(getCookie('loginUUID') || v4());

  const { instance, inProgress } = useMsal();

  const loadTranslations = React.useCallback(async () => {
    try {
      if (location.pathname.search('invite-user') === -1) {
        flxContext.lang = lang;
        const tr = await loader(lang);
        setState({ type: 'translation', data: tr.flx })
      }
    }
    catch(ex) {
      console.log(ex);
    }
  }, [lang])

  /**
   * Check for user's browser language availability in the supported languages
   */
  useEffect(() => {
    loadTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTranslations]);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    flxContext.logout = params.get('from') === 'web';
    if (flxContext.logout) {
      localStorage.removeItem('user');
      navigate(`/${flxContext.lang}`);
    }
  }, [])

  // useEffect(() => {
  //   const callbackId = instance.addEventCallback((event: EventMessage) => {
  //       console.log(event)
  //       if ((event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) && event.payload) {
  //         // Set the active account - this simplifies token acquisition
  //         const authResult = event.payload as AuthenticationResult;
  //         instance.setActiveAccount(authResult.account);
  //         acquireTokenFromFlexwhere('microsoft', authResult.accessToken);
  //       }
  //   });

  //   return () => {
  //       instance.removeEventCallback(callbackId);
  //   }
  // }, [])

  // const acquireTokenFromFlexwhere = (source: 'microsoft' | 'google' | 'apple', token: string) => {
  //   const body = {
  //       platform: 'web',
  //       source: source,
  //       deviceId: uuid.current,
  //       token: token,
  //       bundle: ''
  //   };
  //   post('/api/v1/flw/auth/ssoLogin', body)
  //       .then((response: AxiosResponse<SSOResponse>) => {
  //           console.log(response);
  //           const { data } = response;
  //           if (data.body.length === 1) {
  //               openFlexwhere(data.body[0]);
  //           } else {
  //               console.log(data.body);
  //               // setOrgs(data.body);
  //               navigate(`/microsoft`, { state: { orgs: data.body } });
  //           }
  //       })
  //       .catch((error) => {
  //           console.log(error.response);
  //           if(error?.response?.data?.message === 'ORG_NOT_FOUND') {
  //               // flxContext.error = 'sso-user-not-found';
  //               setState({ type: 'error', data: 'sso-user-not-found' })
  //               setTimeout(() => {
  //                   navigate(`/${flxContext.lang}/`);
  //               }, 100)
  //           } else if(error?.response?.data?.message === 'DEMO_EXPIRED') {
  //               setState({ type: 'error', data: 'demo-expired' });
  //               setTimeout(() => {
  //                   navigate(`/${flxContext.lang}/`);
  //               }, 100)
  //           } else if(error?.response?.data?.message === 'TOTP_ENABLED_SO_NOT_SUPPORTED') {
  //               setState({ type: 'error', data: 'totp' })
  //               setTimeout(() => {
  //                   navigate(`/${flxContext.lang}/totp`);
  //               }, 100)
  //           }
  //           console.error(error);
  //       })
  // }

  // const openFlexwhere = (data: SSOResponseBody) => {
  //   setAuthToken(
  //       `flexwhereAccessToken_${data.subDomain}`,
  //       encodeURIComponent(data.token),
  //       DOMAIN
  //   );
  //   const ONE_YEAR = 365;

  //   //Set the subdomain cookie for redirection
  //   setCookie(`flexwhereSubDomain`, data.subDomain, DOMAIN, ONE_YEAR);

  //   localStorage.setItem('user', JSON.stringify(data));

  //   //Redirect to the subdomain
  //   //By replacing web if it exists in the URL
  //   gotoFlexWhere(data.subDomain);
  // }

  //   /**
  //    * Navigates to FlexWhere org
  //    * @param subDomain the subdomain to navigate to
  //    */
  //   const gotoFlexWhere = (subDomain: string) => {
  //     const proto = window.location.protocol;
  //     instance.clearCache();
  //     const host = window.location.hostname.replace("web", subDomain);
  //     window.location.href = `${proto}//${host}`;
  //   }

  // const pca = React.useMemo(() => {
  //   const app = new PublicClientApplication({
  //     auth: {
  //       clientId: MS_CLIENT_ID,
  //       redirectUri: `https://web.${DOMAIN}/login/microsoft`,
  //       // redirectUri: `http://localhost:3000/login/microsoft`,
  //       navigateToLoginRequestUrl: false,
  //     },
  //     cache: {
  //       cacheLocation: "localStorage",
  //       // secureCookies: false,
  //       storeAuthStateInCookie: false,
  //     },
  //   });
  //   app.initialize();
  //   return app;
  // }, [])

  return (
    <Routes>
      <Route path="/totp/" element={<TotpError />} />
      {!location?.state?.isSubmitted && <Route path="/:lang/signup/" element={<UserForm />} />}
      <Route path="/invalid" element={<Login />} />
      {/* <Route path="/orgs/*" element={<Organisation />} /> */}
      {/* Microsoft SSO */}
      {/* <Route path="/start-microsoft/" element={<MsalProvider instance={pca}> <SsoComponent  provider="microsoft"/> </MsalProvider>} />
      <Route path="/microsoft/*" element={ <MsalProvider instance={pca}> <Organisation/> </MsalProvider> } /> */}
      <Route path="/start-microsoft/" element={<SsoComponent  provider="microsoft"/>} />
      <Route path="/microsoft/*" element={<Organisation/>} />
      {/* Google SSO */}
      <Route path="/start-google/" element={<SsoComponent provider="google"/>} />
      <Route path="/google/*" element={<Organisation />} />
      <Route path="/validatelink/*" element={<Verify />} />
      <Route path="/invite-user/*" element={<InviteUser />} />
      {/* <Route path=":lang/*" element={ <MsalProvider instance={pca}> <Login /> </MsalProvider>} /> */}
      <Route path=":lang/*" element={<Login />} />
      <Route path="*" element={<Navigate to={`/${flxContext.lang}/`} />} />
    </Routes>
  );
}

export const Layout = LayoutComponent;
