import { Box, Button, Typography, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import './booking.scss';
import { get } from "../utils/network";
import { FlwContext } from "../context/FlwContext";
import { useLocation, useNavigate } from "react-router-dom";

type BookingsCompoenntProps = {
    action: 'cancel' | 'retain';
}
const BookingsComponent = (props: BookingsCompoenntProps) => {
    const { action } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const { state: flxContext } = useContext(FlwContext);
    const translation = flxContext.translation;
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        if(action === "cancel" || action === 'retain') {
            cancelOrXtend();
        }
        else {
            navigate(`/${flxContext.lang}`);
        }
    }, [action])

    /**
     * Extend or Cancels the reservation based on the ?userAction queryparameter 
     */
    const cancelOrXtend = async () => {
        try {
            const qp = location.search;
            await get(`/api/v1/flw/reservations/userAction${qp}`);
            setError(undefined);
        }
        catch(exc) {
            setError("expired");
        }
    }

    const detectMob = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const openFlexWhere = () => {
        const token = localStorage.getItem('token');
        if(detectMob()) {
            const newWindow = window.open(`flexwhere://`, "_blank");
            setTimeout(() => {
                window.location.href = `https://${window.location.host}/login`;
                newWindow.close();
            }, 2000)
        } 
        else if(token) {
            window.location.href = `https://${window.location.host}/`;
        }
        else {
            navigate(`/${flxContext.lang}`);
        }
    }

    const CheckMark = () => 
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckRoundedIcon"><path d="M9 16.17 5.53 12.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41a.9959.9959 0 0 0-1.41 0L9 16.17z" fill="white" stroke="white" strokeWidth={1}></path></svg>
    

    const ExtendBooking = () => (
        <Box className="extend-booking" alignItems={"center"} justifyContent={"center"} display={"flex"} flexDirection={"column"}>
            <section className="check extend"><CheckMark /></section>
            <h3> { translation?.login?.bookings?.bookingExtended } </h3>
            <Typography align="center" marginBottom={3}>{ translation?.login?.bookings?.extensionConfirm }</Typography>
            <Button variant="text" onClick={openFlexWhere}>{ translation?.blockedWarning?.openFlexWhere }</Button>
        </Box>
    )

    const CancelBooking = () => (
        <Box className="extend-booking" alignItems={"center"} justifyContent={"center"} display={"flex"} flexDirection={"column"}>
            <section className="check cancel"><CheckMark /></section>
            <h3>{ translation?.emailTemplate_userReservationCancel_subject }</h3>
            <Typography align="center" marginBottom={3}>{ translation?.login?.bookings?.cancelConfirm }</Typography>
            <Button variant="text" onClick={openFlexWhere}>{ translation?.blockedWarning?.openFlexWhere }</Button>
        </Box>
    )

    return (
        <Box maxWidth={"18rem"} p={4} bgcolor={"white"} borderRadius={2} margin={"0 auto"}>
            {/* Initial spinner */}
            { error === "" && 
                <Box margin="1rem auto" maxHeight={"5rem"} justifyContent={"center"} display={"flex"}>
                    <CircularProgress />
                </Box>
            }
            {/* If success */}
            {error === undefined && 
                <>
                    { action === 'cancel' && <CancelBooking/> }
                    { action === 'retain' && <ExtendBooking/> }
                </>
            }
            {/* If Link expires */}
            {error && (
                <React.Fragment>
                    <Box margin="1rem auto" maxHeight={"5rem"} justifyContent={"center"} display={"flex"}>
                        <img src={process.env.PUBLIC_URL + "/flexwhere.svg"} alt="FlexWhere" />
                    </Box>
                    <Typography align="center" color="red">{translation.loginLinkExpired}</Typography>
                </React.Fragment>
            )}
        </Box>
    )
}

export default BookingsComponent;