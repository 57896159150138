import { Button, Grid, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlwContext } from '../../context/FlwContext';
import { post } from '../../utils/network';
import { theme } from '../../utils/theme';

const UserForm = () => {
    const { state: flxContext } = useContext(FlwContext);
    const { translation } = flxContext;
    const navigate = useNavigate();
    const location = useLocation();

    const [displayName, setDisplayName] = React.useState('');
    const [errors, setErrors] = React.useState({ userName: false, displayName: false });

    React.useEffect(() => {
        if (!location?.state?.email) navigate(`/${flxContext.lang}/`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const handleSubmit = async () => {
        try {
            const response = await post('/api/v1/flw/auth/createUser',
                { email: location.state.email, displayName },
                { headers: { 'add-user-token': location.state.addToken } });

            if (response) {
                navigate(`/${flxContext.lang}`, { state: { isSubmitted: true, email: location.state.email, code: response.data.uuid } });
            }
        }
        catch (exc) {
            setErrors({ ...errors, userName: true });
        }
    }

    const isFormValid = React.useMemo(() => {
        // if(!userName) setErrors({...errors, userName: true}); if(!displayName) setErrors({...errors, displayName: true});
        return displayName.length > 0 && displayName.length < 256;
    }, [displayName])

    return (
        <ThemeProvider theme={theme}>
            <Box className="sign-up-form" component={"section"}>
                <Box component={"section"}>
                    <img
                        src={process.env.PUBLIC_URL + "/flexwhere.svg"}
                        alt="FlexWhere"
                    />

                    <Typography marginTop={4} marginBottom={2}>{translation?.login?.userform?.welcome}</Typography>
                    <Typography paddingX={1} lineHeight={2}>{translation?.login?.userform?.details}</Typography>
                    <TextField value={displayName} onChange={e => setDisplayName(e.target.value)} error={errors.displayName} type="text" label={translation?.displayname} variant='standard' fullWidth />
                    <Grid container paddingBottom={2} paddingTop={6}>
                        <Grid item xs={6}>
                            <Button variant='outlined' sx={{ padding: '0.5rem 2rem' }} onClick={() => navigate(-1)}>{translation.back}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' sx={{ padding: '0.5rem 2rem' }} onClick={handleSubmit} disabled={!isFormValid}>{translation.m_btn_submit}</Button>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default UserForm;